const addressTags = {
  "karlsen:qrdn0eyzyc2z4leqwgxcngt98su5gq4p47gz435q8mu2wh8c78502qlfquvnj":
    "(Mexc)",
  "karlsen:qpjnywfxsdrj3nkuscf4mc0m457wt35sgfgfv379zuuekl6z0ulwkkgayg83r":
    "Xeggex",
  "karlsen:qqlahashstjepzax3sa33vdn6a7tul5wvqs8adzgrr6lzt280ctw5my64p427":
    "NonKYC",
};

export default addressTags;
